<template>
  <!-- TODO: REMOVE PLACEHOLDERS AND CALL ACTUAL STATS FROM BACKEND API -->

  <!-- TODO: REMOVE UNWANTED DATAPOINTS -->
  <!-- TODO: REFORMAT GRAPH AND SET AS LINE GRAPH INSTEAD OF SPLIT BAR -->
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col xl="4" md="6">
        <quick-case-search :is-loaded="isLoaded" @loaded="isLoaded += 1" />
      </b-col>
      <b-col xl="8" md="6">
        <user-statistics :is-loaded="isLoaded" @loaded="isLoaded += 1" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="4">
        <b-row class="match-height">
          <b-col lg="12" md="6">
            <filing-breakdown-dough
              :is-loaded="isLoaded"
              @loaded="isLoaded += 1"
            />
          </b-col>
        </b-row>
      </b-col>

      <b-col lg="8">
        <cases-filed-chart :is-loaded="isLoaded" @loaded="isLoaded += 1" />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="8">
        <dash-notifications-table
          :is-loaded="isLoaded"
          @loaded="isLoaded += 1"
        />
      </b-col>

      <b-col lg="4" md="6">
        <filed-case-area-chart :is-loaded="isLoaded" @loaded="isLoaded += 1" />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue"

import { getUserData } from "@/auth/utils"

import QuickCaseSearch from "./QuickCaseSearch.vue"
import UserStatistics from "./UserStatistics.vue"
//import FilingBreakdown from "./FilingBreakdown.vue"
import FilingBreakdownDough from "./FilingBreakdownDough.vue"
import CasesFiledChart from "./CasesFiledChart.vue"
import DashNotificationsTable from "./DashNotificationsTable"
import FiledCaseAreaChart from "./FiledCaseAreaChart"

//import EcommerceMeetup from "./EcommerceMeetup.vue"

export default {
  components: {
    BRow,
    BCol,
    FilingBreakdownDough,
    QuickCaseSearch,
    UserStatistics,
    CasesFiledChart,
    DashNotificationsTable,
    FiledCaseAreaChart

    //EcommerceMeetup
  },
  data() {
    return {
      isLoaded: 0,
      data: {}
    }
  },
  created() {
    // data
    this.$http.get("/ecommerce/data").then((response) => {
      ;(response.data.companyTable = [
        {
          avatarImg: "BellIcon",
          title: "37GS1-2020-CV-1037",
          subtitle: "",
          avatarIcon: "MonitorIcon",
          avatarColor: "light-warning",
          avatarTitle: "Garnish / Levy",
          viewTitle: "Answered",
          viewsub: "Served",
          revenue: "Served",
          sales: "2/19/2021",
          loss: true
        },
        {
          avatarImg: "BellIcon",
          title: "37GS1-2020-CV-1115",
          subtitle: "",
          avatarIcon: "CoffeeIcon",
          avatarColor: "light-warning",
          avatarTitle: "Dismissed",
          viewTitle: "Yes",
          viewsub: "in 2 days",
          revenue: "No",
          sales: "2/19/2021",
          loss: false
        },
        {
          avatarImg: "BellIcon",
          title: "37GS1-2020-CV-902",
          subtitle: "",
          avatarIcon: "WatchIcon",
          avatarColor: "light-warning",
          avatarTitle: "Garnish / Levy",
          viewTitle: "Answered",
          viewsub: "Served",
          revenue: "Served",
          sales: "2/19/2021",
          loss: false
        },
        {
          avatarImg: "BellIcon",
          title: "37GS1-2018-CV-433",
          subtitle: "",
          avatarIcon: "MonitorIcon",
          avatarColor: "light-warning",
          avatarTitle: "Garnish / Levy",
          viewTitle: "Issued",
          viewsub: "Served",
          revenue: "None",
          sales: "2/19/2021",
          loss: false
        },
        {
          avatarImg: "BellIcon",
          title: "32GS1-2018-CV-127503",
          subtitle: "",
          avatarIcon: "CoffeeIcon",
          avatarColor: "light-warning",
          avatarTitle: "Garnish / Levy",
          viewTitle: "Issued",
          viewsub: "Served",
          revenue: "None",
          sales: "2/19/2021",
          loss: true
        },
        {
          avatarImg: "BellIcon",
          title: "32GS1-2018-CV-128716",
          subtitle: "",
          avatarIcon: "WatchIcon",
          avatarColor: "light-warning",
          avatarTitle: "Fashion",
          viewTitle: "990",
          viewsub: "in 1 month",
          revenue: "780.05",
          sales: "2/19/2021",
          loss: false
        },
        {
          avatarImg: "BellIcon",
          title: "82GS6-2017-VK-38829",
          subtitle: "",
          avatarIcon: "WatchIcon",
          avatarColor: "light-warning",
          avatarTitle: "Fashion",
          viewTitle: "12.9k",
          viewsub: "in 12 hours",
          revenue: "531.49",
          sales: "2/19/2021",
          loss: false
        }
      ]),
        (response.data.statisticsItems = [
          {
            icon: "KeyIcon",
            color: "light-primary",
            title: "5",
            subtitle: "County Subscriptions",
            customClass: "mb-2 mb-xl-0"
          },
          {
            icon: "TrendingUpIcon",
            color: "light-info",
            title: "11.549k",
            subtitle: "Law Suits - Last 30 Days",
            customClass: "mb-2 mb-xl-0"
          },
          {
            icon: "MapPinIcon",
            color: "light-danger",
            title: "27",
            subtitle: "Tracked Cases",
            customClass: "mb-2 mb-sm-0"
          },
          {
            icon: "DollarSignIcon",
            color: "light-success",
            title: "14",
            subtitle: "New Client Contacts last 7 days",
            customClass: ""
          }
        ])
      ;(response.data.statisticsOrder = {
        series: [
          {
            name: "2020",
            data: [45, 85, 65, 45, 65]
          }
        ]
      }),
        (this.data = response.data)

      // ? Your API will return name of logged in user or you might just directly get name of logged in user
      // ? This is just for demo purpose
      // eslint-disable-next-line no-unused-vars
      const userData = getUserData()
      this.data.congratulations.name = ""
    })
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
