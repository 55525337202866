<template>
  <b-overlay :show="isBusy" rounded="lg" opacity="0.6">

    <b-modal id="modal-center" v-model="showModal" scrollable header-class="bg-white" hide-footer size="xl">
      <!-- <p class="my-4">{{ modalValue.item }}</p> -->
      <div v-if="showModal">
        <total-case-view :casedata="caseData"> </total-case-view>
      </div>
    </b-modal>

    <b-card class="card-congratulation-medal">
      <h4 class="card-title">Quick Case Search</h4>

      <div v-if="errorMessage" class='text-warning'>
        {{ errorMessage }}
      </div>
      <div v-if="!errorMessage" class='invisible'>
        errors
      </div>

      <div>
        <b-row>
          <b-col>

            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input v-model="searchQueryNumber" placeholder="Case Number" @click="searchQueryID = ''"
                @keyup.enter="submitSearch" />
            </b-input-group>
          </b-col>
          <b-col>

            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>
              <b-form-input v-model="searchQueryID" type="number" placeholder="Case ID" @click="searchQueryNumber = ''"
                @keyup.enter="submitSearch" />
            </b-input-group>
          </b-col>
        </b-row>

        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="float-right mt-1"
          @click="submitSearch">
          Submit
        </b-button>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BRow,
  BCol

} from "bootstrap-vue"
import TotalCaseView from "../../components/total-case-view/TotalCaseView.vue";
import Ripple from "vue-ripple-directive"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BOverlay,
    BInputGroupPrepend,
    BFormInput,
    TotalCaseView,
    //BLink,
    BInputGroup,
    //BImg,
    BButton
  },
  directives: {
    Ripple
  },
  props: {
    isLoaded: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isBusy: true,
      searchQueryNumber: "",
      searchQueryID: "",
      errorMessage: '',
      caseData: '',
      showModal: false,
    }
  },
  watch: {
    isLoaded(newValue) {
      if (newValue >= 2) {
        this.isBusy = false
      }
    }
  },
  methods: {
    submitSearch() {

      this.errorMessage = ''

      const queryType = this.searchQueryNumber ? 'number' : 'id'

      const query = queryType === 'number' ? this.searchQueryNumber : this.searchQueryID

      if (query) {



        this.isBusy = true;
        this.$AuthAxios.instance
          .get("/api-access/search/case-search/quick/", {
            params: {
              searchQuery: query,
              searchType: queryType,
            },
          })
          .then((res) => {
            console.log(res);
            this.isBusy = false;
            this.caseData = res.data;
            this.showModal = true;
          })
          .catch((error) => {
            this.isBusy = false;
            if (error.response) {

              console.log("Error status:", error.response.status);
              console.log("Error data:", error.response.data);

              this.errorMessage = error.response.data.error
            } else {
              this.errorMessage = "An error occurred while searching for the case."
            }
          });
      }
    },

  }
}
</script>
<style scoped>
.search-box {
  margin-bottom: 1rem;
}
</style>