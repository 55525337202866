<template>
  <b-overlay :show="isBusy" rounded="lg" opacity="0.6" no-body>
    <b-card no-body>
      <b-card-header class="align-items-baseline">
        <div>
          <b-card-title>Filings Breakdown </b-card-title>
          <b-card-text class="mb-0">
            Cases Filed - Last 12 Months
          </b-card-text>
        </div>
      </b-card-header>

      <b-card-body class="pb-0">
        <div class="w-full mr-1 d-inline-flex align-items-center">
          <b-form-group v-slot="{ ariaDescribedby }" class="w-full">
            <b-form-radio-group
              id="radio-group-2"
              v-model="name"
              :aria-describedby="ariaDescribedby"
              name="radio-sub-component"
              @change="changeData()"
            >
              <b-form-radio value="case_status">Case Status</b-form-radio>
              <b-form-radio value="case_type">Case Types</b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>

        <div class="my-1">
          <!-- apex chart -->
          <vue-apex-charts
            :key="refreshKey"
            height="274"
            :options="statisticsRadarChart.chartOptions"
            :series="seriesData.series"
          />
        </div>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText
} from "bootstrap-vue"
import VueApexCharts from "vue-apexcharts"
import { $themeColors } from "@themeConfig"

export default {
  components: {
    BOverlay,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    VueApexCharts,

    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody
  },
  props: {
    isLoaded: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isBusy: true,
      refreshKey: 0,
      loaded: false,
      name: "case_status",
      duration: { months: 60 },
      baseStatus: {},
      baseType: {},
      seriesData: {
        series: [27, 59, 12, 1, 0, 1, 0]
      },
      statisticsRadarChart: {
        chartOptions: {
          chart: {
            type: "donut",
            toolbar: {
              show: true
            },
            dropShadow: {
              enabled: true,
              enabledOnSeries: undefined,
              top: 0,
              left: 0,
              blur: 3,
              color: "#000",
              opacity: 0.35
            }
          },
          dataLabels: {
            enabled: false
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: [
            "Appealed",
            "Filed - Not Op...",
            "Open (Status c...",
            "Reopened",
            "Closed",
            "Disposed",
            "Open"
          ],
          stroke: { width: 0 },
          colors: [
            $themeColors.success,
            $themeColors.primary,
            $themeColors.secondary,
            $themeColors.warning,
            $themeColors.info,
            $themeColors.danger
          ],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20
            }
          },
          plotOptions: {
            pie: {
              startAngle: -10,
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    }
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: "Open",
                    formatter: function(w) {
                      return w.globals.series[0].toString() + "%"
                    }
                  }
                }
              }
            }
          },
          fill: {
            type: "gradient",
            gradient: {
              opacityFrom: 0.8,
              opacityTo: 0.9
            }
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100
                }
              }
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120
                }
              }
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100
                }
              }
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120
                }
              }
            }
          ]
        }
      }
    }
  },
  watch: {
    isLoaded(newValue) {
      if (newValue >= 2) {
        this.isBusy = false
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    getDataALL() {
      return this.seriesData ? this.seriesData.series[0] + "%" : "53%"
    },
    changeData() {
      if (this.name === "case_type") {
        this.seriesData.series = this.baseType.series[0].data
        this.statisticsRadarChart.chartOptions.labels = this.baseType.options
        this.statisticsRadarChart.chartOptions.plotOptions.pie.donut.labels.total.label = this.baseType.options[0]
      } else {
        this.seriesData.series = this.baseStatus.series[0].data
        this.statisticsRadarChart.chartOptions.labels = this.baseStatus.options
        this.statisticsRadarChart.chartOptions.plotOptions.pie.donut.labels.total.label = this.baseStatus.options[0]
      }
      this.refreshGraph()
    },
    refreshGraph() {
      this.refreshKey += 1
    },
    getData() {
      this.$AuthAxios.instance
        .get("/api-access/dashboard/statistics/filings-breakdown/", {
          params: { params: { duration: this.duration } }
        }) // eslint-disable-next-line no-unused-vars
        .then((res) => {
          this.seriesData.series = res.data.status.series[0].data
          this.statisticsRadarChart.chartOptions.labels =
            res.data.status.options
          this.statisticsRadarChart.chartOptions.plotOptions.pie.donut.labels.total.label =
            res.data.status.options[0]
          this.baseStatus = res.data.status
          this.baseType = res.data.type
          this.loaded = true
          this.refreshGraph()
          this.$emit("loaded")
        })
    }
  }
}
</script>
