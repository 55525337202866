<template>
  <b-overlay :show="isBusy" rounded="lg" opacity="0.6">
    <b-card no-body>
      <b-card-header class="align-items-baseline">
        <div>
          <b-card-title>Month-To-Month Overview</b-card-title>
          <b-card-text class="mb-0">
            {{ numOfYears + 1 }} Year Historical Monthly Averages
          </b-card-text>
        </div>
      </b-card-header>

      <b-card-body>
        <div class="mr-2 d-inline-flex align-items-center">
          <feather-icon icon="CircleIcon" class="text-info mr-50" />
          <span>Totals for {{ currentYear }}</span>
        </div>
        <div class="d-inline-flex align-items-center">
          <feather-icon icon="CircleIcon" class="text-primary mr-50" />
          <span>Averages for {{ allYears }}</span>
        </div>
        <!-- apex chart -->
        <vue-apex-charts
          :key="resetKey"
          type="radar"
          height="275"
          :options="statisticsRadar.chartOptions"
          :series="salesChart.series"
        />
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText
} from "bootstrap-vue"
import VueApexCharts from "vue-apexcharts"
import { $themeColors } from "@themeConfig"

export default {
  components: {
    BOverlay,
    VueApexCharts,
    BCardText,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody
  },
  props: {
    isLoaded: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isBusy: true,
      loaded: false,
      numOfYears: "",
      currentYear: "",
      allYears: "",
      resetKey: 0,
      salesChart: {
        series: [
          {
            name: "Sales",
            data: [
              2188.6666666666665,
              2051,
              1782,
              1849.6666666666667,
              2127,
              1993,
              1807,
              1864.3333333333333,
              1723.6666666666667,
              1825.3333333333333,
              2041,
              1550.3333333333333
            ]
          },
          {
            name: "Visit",
            data: [
              1872,
              1437,
              1210,
              1316,
              1325,
              1508,
              1800,
              1637,
              1510,
              1427,
              1369,
              900
            ]
          }
        ]
      },
      statisticsRadar: {
        chartOptions: {
          chart: {
            height: 300,
            type: "radar",
            dropShadow: {
              enabled: true,
              blur: 8,
              left: 1,
              top: 1,
              opacity: 0.2
            },
            toolbar: {
              show: false
            },
            offsetY: 5
          },
          stroke: {
            width: 0
          },
          dataLabels: {
            background: {
              foreColor: ["#ebe9f1"]
            }
          },
          legend: { show: false },
          colors: [$themeColors.primary, $themeColors.info],
          plotOptions: {
            radar: {
              polygons: {
                strokeColors: [
                  "#ebe9f1",
                  "transparent",
                  "transparent",
                  "transparent",
                  "transparent",
                  "transparent"
                ],
                connectorColors: "transparent"
              }
            }
          },
          fill: {
            type: "gradient",
            gradient: {
              shade: "dark",
              gradientToColors: [$themeColors.primary, $themeColors.success],
              shadeIntensity: 1,
              type: "horizontal",
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100, 100, 100]
            }
          },
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          markers: {
            size: 0
          },
          yaxis: {
            show: false
          },
          grid: {
            show: false,
            padding: {
              bottom: -27
            }
          }
        }
      }
    }
  },
  watch: {
    isLoaded(newValue) {
      if (newValue >= 2) {
        this.isBusy = false
      }
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    refreshGraph() {
      this.refreshKey += 1
    },
    getData() {
      this.$AuthAxios.instance
        .get(
          "/api-access/dashboard/statistics/graphs/cases-filed/month-to-month/",
          {}
        ) // eslint-disable-next-line no-unused-vars
        .then((res) => {
          this.salesChart.series = res.data.data
          this.statisticsRadar.chartOptions.labels = res.data.months
          this.currentYear = res.data.current_year
          this.allYears = res.data.years
          this.numOfYears = res.data.num_of_years

          this.refreshGraph()

          this.$emit("loaded")
        })
    }
  }
}
</script>
