<template>
  <b-overlay :show="isBusy" rounded="lg" opacity="0.6">
    <b-card v-if="data" no-body class="pb-1 card-statistics">
      <b-card-header>
        <b-card-title>Statistics</b-card-title>
        <b-card-text class="mb-0 font-small-2 mr-25">
          Updated 3 hours ago
        </b-card-text>
      </b-card-header>
      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in data"
            :key="item.icon"
            xl="3"
            sm="6"
            :class="item.customClass"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" :variant="item.color">
                  <feather-icon size="24" :icon="item.icon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="mb-0 font-weight-bolder">
                  {{ kFormatter(item.title) }}
                </h4>
                <b-card-text class="mb-0 font-small-3">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import { kFormatter } from "@core/utils/filter"
import {
  BOverlay,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody
} from "bootstrap-vue"

export default {
  name: "UserStatistics",
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody
  },
  props: {
    isLoaded: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      isBusy: true,
      loaded: true,
      data: []
    }
  },
  watch: {
    isLoaded(newValue) {
      if (newValue > 1) {
        this.isBusy = false
      }
    }
  },
  mounted() {
    this.getData()
  },

  methods: {
    kFormatter,
    getData() {
      this.$AuthAxios.instance
        .get("/api-access/dashboard/statistics/user/", {})
        .then((res) => {
          this.data = res.data.stats
          this.$emit("loaded")
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }
}
</script>
