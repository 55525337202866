<template>
  <b-overlay :show="isBusy" rounded="lg" opacity="0.6">
    <b-card v-if="loaded" no-body class="pb-1 card-revenue-budget">
      <b-row class="mx-0">
        <b-col class="revenue-report-wrapper">
          <div
            class="mb-3 d-sm-flex justify-content-between align-items-center"
          >
            <h4 class="card-title mb-50 mb-sm-0">Cases Filed by Month</h4>
            <div class="d-flex align-items-center">
              <div
                v-for="(value, key) in revenue.revenueReport.series"
                :key="key"
                class="ml-10 d-flex align-items-center"
              >
                <span
                  :class="`cursor-pointer bullet ${
                    key === 0
                      ? 'bullet-primary'
                      : key === 1
                      ? 'bullet-secondary'
                      : 'bullet-warning'
                  } svg-font-small-3 mr-50`"
                />
                <span>{{ value.name }}</span>
              </div>
            </div>
          </div>

          <!-- chart -->
          <vue-apex-charts
            id="revenue-report-chart"
            type="area"
            height="300"
            :options="revenueReport.chartOptions"
            :series="revenue.revenueReport.series"
          />
        </b-col>
      </b-row>
    </b-card>
    <b-card v-else no-body class="card-revenue-budget">
      <b-row class="mx-0">
        <b-col class="revenue-report-wrapper">
          <div
            class="mb-3 d-sm-flex justify-content-between align-items-center"
          >
            <h4 class="card-title mb-50 mb-sm-0">Cases Filed by Month</h4>
            <div class="d-flex align-items-center">
              <div
                v-for="(value, key) in revenue.revenueReport.series"
                :key="key"
                class="ml-10 d-flex align-items-center"
              >
                <span
                  :class="`cursor-pointer bullet ${
                    key === 0
                      ? 'bullet-primary'
                      : key === 1
                      ? 'bullet-secondary'
                      : 'bullet-warning'
                  } svg-font-small-3 mr-50`"
                />
                <span>{{ value.name }}</span>
              </div>
            </div>
          </div>

          <!-- chart -->
          <vue-apex-charts
            id="revenue-report-chart"
            type="area"
            height="300"
            :options="revenueReport.chartOptions"
            :series="revenue.revenueReport.series"
          />
        </b-col>
      </b-row>
      {{ revenueReport }}
    </b-card>
  </b-overlay>
</template>

<script>
//<b-overlay :show="isBusy" rounded="lg" opacity="0.6">
import {
  BCard,
  BRow,
  BCol,
  BOverlay,
  //BDropdown,
  //BDropdownItem,
  //BButton
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BOverlay,
    VueApexCharts,
    // BDropdown,
    // BDropdownItem,
    BCard,
    // BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    isLoaded: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isBusy: true,
      revenue: {
        revenueReport: {
          series: [
            {
              name: "2021",
              data: [
                900, 1369, 1427, 1510, 1637, 1800, 1508, 1325, 1316, 1210, 1437,
                1872,
              ],
            },
            {
              name: "2020",
              data: [
                1594, 1723, 1583, 1174, 837, 1275, 1972, 2250, 1756, 1686, 2163,
                2346,
              ],
            },
            {
              name: "2019",
              data: [
                2157, 3031, 2466, 2487, 3119, 2346, 2499, 2806, 2477, 2450,
                2553, 2348,
              ],
            },
          ],
        },
      },
      loaded: false,

      revenueReport: {
        chartOptions: {
          chart: {
            stacked: false,
            type: "bar",
            toolbar: { show: true },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: true },
            },
          },
          xaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },

          colors: [
            $themeColors.primary,
            $themeColors.secondary,
            $themeColors.warning,
          ],
          plotOptions: {
            bar: {
              columnWidth: "17%",
              endingShape: "rounded",
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
          },
        },
      },
    };
  },
  watch: {
    isLoaded(newValue) {
      if (newValue >= 2) {
        this.isBusy = false;
      }
    },
  },
  mounted() {
    this.get_graph_data();
  },
  methods: {
    get_graph_data() {
      //statistics/graphs/cases-filed/year-over-year/
      this.$AuthAxios.instance
        .get(
          "/api-access/dashboard/statistics/graphs/cases-filed/year-over-year/",
          {}
        )
        .then((res) => {
          this.revenue.revenueReport.series = res.data.data;
          this.revenueReport.chartOptions.xaxis.categories = res.data.months;

          this.loaded = true;
          this.$emit("loaded");
        });
    },
  },
};
</script>
