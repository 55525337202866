<template>
  <b-overlay :show="isBusy" rounded="lg" opacity="0.6" no-body>
    <b-card v-if="true" no-body class="card-company-table">
      <div>
        <b-table
          ref="table"
          class="w-full mb-0 min-h-6xl"
          no-local-sorting
          show-empty
          selectable
          border
          select-mode="multi"
          responsive
          :items="callAPI"
          :fields="fields"
          :tbody-tr-class="rowClass"
          :per-page="perPage"
          :current-page="currentPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          @row-selected="onRowSelected"
          @filtered="onFiltered"
          @sort-changed="onFiltered"
        >
          <template #cell(defendant)="data">
            <div class="flex flex-row">
              <div class="font-bold truncate max-w-xxs">
                {{ data.item.defendant_name }}
              </div>
            </div>
          </template>

          <template #cell(date)="data">
            <div class="flex flex-row font-bold truncate">
              {{ data.item.created_date }}
            </div>
          </template>
          <template #cell(view)="data">
            <b-button
              variant="gradient-secondary"
              class="shadow-md btn-icon"
              size="sm"
              @click="viewNotification(data.id)"
            >
              <div class="flex flex-row items-center justify-center">
                <div class="flex flex-row text-xs">View</div>
              </div>
            </b-button>
          </template>
          <template #cell(field)="data">
            <div class="font-bold">{{ data.item.field | capitalize }}</div>
          </template>
          <template #cell(current)="data">
            <div class="flex flex-row bg-gray-100 rounded-sm shadow-sm">
              <div
                class="
                  flex flex-row
                  items-center
                  justify-center
                  px-1
                  mr-1
                  text-gray-200
                  rounded-sm
                  shadow-md
                  bg-success
                "
              >
                <feather-icon icon="ActivityIcon" />
              </div>

              <div class="">{{ data.item.current_value }}</div>
            </div>
          </template>
          <template #cell(previous)="data">
            <div
              class="
                flex flex-row
                items-center
                justify-center
                bg-gray-100
                rounded-sm
                shadow-sm
              "
            >
              {{ data.item.previous_value }}
            </div>
          </template>
          <template #cell(notes)="data">
            <div class="flex flex-row items-center justify-center font-bold">
              <div
                :class="{
                  'text-success':
                    data.item._rowVariant === 'success' ? false : true,
                }"
              >
                <feather-icon
                  v-if="data.item.has_tracker_notes"
                  icon="CheckCircleIcon"
                  size="20"
                />
                <feather-icon v-else icon="XCircleIcon" size="20" />
              </div>
            </div>
          </template>
          <template #table-busy>
            <div
              class="
                flex flex-col
                items-center
                justify-center
                my-2
                text-center
                h-72
                text-danger
              "
            >
              <b-spinner class="align-middle"></b-spinner>
              <strong>Loading...</strong>
            </div>
          </template>
        </b-table>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { BSpinner, BTable, BButton, BCard, BOverlay } from "bootstrap-vue";

export default {
  components: {
    BSpinner,
    BTable,
    BButton,
    BCard,
    BOverlay,
  },
  props: {
    isLoaded: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isBusy2: true,
      fieldBy: "case_status",
      filterBy: "none",
      searchFilter: "",
      notificationsDateTo: "",
      notificationsDateFrom: "",
      checkboxSelected: ["notes"],
      // SELECTED VARS
      allChecked: false,
      indeterminateChecked: false,
      checkedViaRow: false,
      selectedRows: [],
      // LOCAL VARS FOR TABLE SORTING & PAGINATION
      pageOptions: [5, 7, 10, 15, { value: 100, text: "Show a lot" }],
      perPage: 10,
      isBusy: true,
      currentPage: 1,
      sortDesc: false,
      sortBy: "viewed",
      totalRows: 5,
      // LOCAL VARIABLES
      caseData: [],
      showModal: false,
      tableData: "",

      fields: [
        {
          key: "defendant",
          label: "Defendant",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "date",
          label: "Date",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "view",
          label: "View",
        },
        {
          key: "field",
          label: "Updated Field",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "current",
          label: "Updated Value",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "previous",
          label: "Previous Value",
          sortable: true,
          sortDirection: "desc",
        },
        {
          key: "notes",
          label: "User Notes",
          sortable: true,
          sortDirection: "desc",
        },
      ],
    };
  },
  computed: {
    checkSelect() {
      return this.selectedRows.length > 0 ? true : false;
    },
  },
  watch: {
    isLoaded(newValue) {
      if (newValue >= 2) {
        this.isBusy = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    allChecked(newValue, oldValue) {
      if (newValue) {
        this.$refs.table.selectAllRows();

        return;
      }
      if (!newValue && !this.indeterminateChecked) {
        this.$refs.table.clearSelected();

        return;
      }
    },
  },
  methods: {
    viewNotification(id) {
      if (this.$route.name !== "notifications") {
        this.$router.push({
          name: "notifications",
        });
      }
      this.$store.dispatch("notifications/updateSelectedNotification", id);
    },
    packageQuery(x, callback) {
      let pkg = {
        fieldBy: this.fieldBy,
        filterBy: this.filterBy,
        searchFilter: this.searchFilter,
        notificationsDateTo: this.notificationsDateTo,
        notificationsDateFrom: this.notificationsDateFrom,
        checkboxSelected: this.checkboxSelected,
      };
      callback(pkg);
    },
    setSelectAll() {
      this.indeterminateChecked = false;
    },
    async onRowSelected(items) {
      if (items.length < 1) {
        await this.setSelectAll();
        this.allChecked = false;
      } else {
        this.indeterminateChecked = true;
      }
      this.selectedRows = items;
    },
    selectCheckbox(index, status) {
      if (status) {
        return this.$refs.table.unselectRow(index);
      }
      return this.$refs.table.selectRow(index);
    },

    refreshTable() {
      this.$refs.table.refresh();
    },
    async callAxios(ctx) {
      let query;
      await this.packageQuery(null, (x) => {
        query = x;
      });

      var x = (ctx.currentPage - 1) * this.perPage;
      if (x < 0) {
        x = x - 1;
      }
      ctx["limit"] = ctx.perPage;
      ctx["offset"] = x;

      query["ctx"] = ctx;

      await this.$AuthAxios.instance
        .get("/api-access/notifications/get-notification-table/", {
          params: {
            filters: query,
          },
        })
        .then((res) => {
          this.tableData = res;
        }) // eslint-disable-next-line no-unused-vars
        .catch((error) => {
          console.log(error);
        });
    },
    onFiltered() {},
    async callAPI(ctx) {
      await this.callAxios(ctx);

      this.totalRows = this.tableData.data.count;

      return this.tableData.data.results;
    },
    rowClass(item, type) {
      if (!item || type !== "row") return "relative";
      if (item._rowVariant === "success") return "relative table-success";
    },
    deleteNotifications() {
      this.$AuthAxios.instance
        .delete("/api-access/notifications/delete/", {
          params: {
            filters: {
              notifications: this.selectedRows,
            },
          },
        }) // eslint-disable-next-line no-unused-vars
        .then((res) => {
          this.$refs.table.refresh();
          this.$GetNotifications(this.$AuthAxios, this.$store);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getCaseData(case_number) {
      this.$AuthAxios.instance
        .get("/api-access/search/case-search/", {
          params: {
            single: true,
            caseNumber: case_number,
          },
        })
        .then((res) => {
          this.caseData = res.data.results[0];
          this.showModal = true;
        });
    },
  },
};
</script>
